import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo_b.png";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { hostname } = document.location;
  const { t } = useTranslation();
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={classes.info}>
          <div className={classes.contacts}>
            <div className={[classes.contactsFirst, ""].join(" ")}>
              <p>
                <Trans>footer_contacts</Trans>
              </p>
              <a href="#">Turkey, Shishli, Mecidiyekoy, Latin Lokum sokak, haci halilkafar iş hanı no 6, kat 3</a>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
            </div>
            <div className={[classes.contactsSecond, ""].join(" ")}>   <a><Trans>footer_phones</Trans></a>
              <a href="tel:905528328260">+905528328260</a>
              <a href="tel:905321350273">+905321350273</a>
              <a href="tel:905550743505">+905550743505</a></div>



          </div>
          <div className={classes.docs}>
            <a href="#">
              <Trans>footer_terms_conditions</Trans>
            </a>
            <a href="#">
              <Trans>footer_privacy_policy</Trans>
            </a>
            <a href="#">
              <Trans>footer_aml_policy</Trans>
            </a>
          </div>
        </div>
        <div className={classes.disclaimer}>
          <p className={classes.disclaimerTitle}>
            <Trans>footer_title_desclaimer</Trans>
          </p>
          <p>
            <Trans>footer_desclaimer</Trans>
          </p>
          <p className={classes.rights}>
            <Trans>footer_copyright</Trans>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
